import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

import Accreditations from "../components/Accreditations";
import Footer from "../components/Footer";
import ImageBanner from "../components/ImageBanner";
import NewsArticles from "../components/NewsArticles";
// import Twitter from '../components/Twitter';
import Scroller from "../components/Scroller";
import Header from "../components/Header";

import { ArrowRightOutlined } from "@ant-design/icons";

import content from "../data/pageContent.json";
import { handleNews, fetchArticles } from "../functions/cms";
import { compileLiveDataList, fetchAPIData } from "../functions/helpers";
import { Button } from "antd";

const apiDetails = {
  account: process.env.REACT_APP_API_ACCOUNT,
  containerName: process.env.REACT_APP_API_CONTAINER_NAME,
  blobName: process.env.REACT_APP_API_BLOB_NAME,
  sas: process.env.REACT_APP_API_SAS_TOKEN,
};

const Home = () => {
  const page = content.home;
  let navigate = useNavigate();

  const [news, setNews] = useState(null);
  const [liveSensors, setLiveSensors] = useState([]);

  // Set Page Title
  useEffect(() => {
    document.title = page["page-title"];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if (cookies?.accepted) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  // Fetch Monitor Data on Page Load
  useEffect(() => {
    (async function handleLiveData() {
      const output = await fetchAPIData(apiDetails);
      try {
        const parsedData = JSON.parse(output);
        // console.log(parsedData);
        setLiveSensors(compileLiveDataList(parsedData));
      } catch (err) {
        setLiveSensors([]);
        console.log({ err });
      }
    })();
  }, []);

  useEffect(() => {
    fetchArticles(process.env.REACT_APP_NEWS_ARTICLES)
      .then((response) => {
        const output = handleNews(response);
        setNews(output);
      })
      .catch(() => {
        console.log("Failed To Fetch Data");
      });
  }, []);

  const SubHeaderContent = (
    <div className="center">
      <Button size="large" onClick={() => navigate("/about-us")}>
        Find out more <ArrowRightOutlined />
      </Button>
    </div>
  );

  const TitleContent = (
    <div className="lightText">
      Welcome to <span className="lightweight">Essex</span>Air
    </div>
  );

  return (
    <>
      <main>
        <ImageBanner
          image={page.image}
          wide={true}
          title={page.title}
          subtitle={page.subtitle}
        />

        <Header
          subtitle={page.subtitle}
          title={TitleContent}
          children={SubHeaderContent}
        />

        <div className={"body"}>
          <div className={"body-container"}>
            <section>
              <h3 className={"section-header"}>
                Why is air quality so important?
              </h3>
              <p>
                Air pollution is the largest environmental risk to public health
                in the UK and was attributed to between 26,000 and 38,000 adult
                deaths in England in 2019. It reduces life expectancy by causing
                cardiovascular and respiratory diseases and lung cancer.
              </p>
              <p>
                While air pollution effects everyone, children, older people or
                people with pre-existing health conditions are more likely to be
                susceptible.
              </p>
              <p>
                Therefore, improved air quality in Essex will not only improve
                people's own health and that of their families, but also protect
                the most vulnerable.
              </p>
              <p>
                This website includes useful information about air pollution,
                its impacts and what we can all do to{" "}
                <Link to="/what-can-i-do">help improve air quality</Link> in
                Essex.
              </p>
            </section>

            <Scroller items={liveSensors} />

            <NewsArticles
              title={"Latest News"}
              type={"news"}
              data={news}
              limit={3}
            />

            {/* <Twitter/> */}

            <Accreditations />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
