import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Tabs } from "antd";
import ReactGA from "react-ga4";

import Footer from "../components/Footer";
import ImageBanner from "../components/ImageBanner";
import MarkdownContent from "../components/MarkdownContent";

import content from "../data/pageContent.json";
import s from "./SchoolZone.module.css";

const AirQualityStrategy = (props) => {
  const [tabSelection, setTabSelection] = useState({
    main: "intro",
    intro: "background",
    strategy: "strategy",
    consultation: "link",
  });

  const page = content["air-quality-strategy"];

  useEffect(() => {
    document.title = page["page-title"];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if (cookies?.accepted) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  const handleMainTab = (e) => {
    setTabSelection({ ...tabSelection, main: e });
  };

  const handleSubTab = (val, k) => {
    setTabSelection({ ...tabSelection, [k]: val });
  };

  const introBackground =
    tabSelection.main === "intro" && tabSelection.intro === "background";

  const introWhy =
    tabSelection.main === "intro" && tabSelection.intro === "why";

  const introVision =
    tabSelection.main === "intro" && tabSelection.intro === "vision";

  const strategyStrategy =
    tabSelection.main === "strategy" && tabSelection.strategy === "strategy";

  const strategyDocs =
    tabSelection.main === "strategy" && tabSelection.strategy === "docs";

  const strategyPlan =
    tabSelection.main === "strategy" && tabSelection.strategy === "plan";

  const consultationViews =
    tabSelection.main === "consultation" &&
    tabSelection.consultation === "views";

  const consultationLink =
    tabSelection.main === "consultation" &&
    tabSelection.consultation === "link";

  const steps = tabSelection.main === "steps";

  return (
    <main>
      <ImageBanner
        wide={true}
        title={page.title}
        subtitle={page.subtitle}
        image={page.image}
      />

      <Header
        image={page.image}
        wide={true}
        title={page.title}
        subtitle={page.subtitle}
      >
        <Tabs
          centered
          size={"large"}
          className={s.tabs}
          defaultActiveKey="intro"
          onChange={handleMainTab}
          items={[
            {
              label: `Introduction`,
              key: "intro",
              children: (
                <Tabs
                  centered
                  size={"large"}
                  className={s.tabs}
                  defaultActiveKey="background"
                  onChange={(e) => handleSubTab(e, "intro")}
                  items={[
                    {
                      label: `Background`,
                      key: "background",
                      children: <></>,
                    },
                    {
                      label: `Why?`,
                      key: "why",
                      children: <></>,
                    },
                    {
                      label: `Vision and Aims`,
                      key: "vision",
                      children: <></>,
                    },
                  ]}
                />
              ),
            },
            {
              label: `Essex Air Quality Strategy`,
              key: "strategy",
              children: (
                <Tabs
                  centered
                  size={"large"}
                  className={s.tabs}
                  defaultActiveKey="strategy"
                  onChange={(e) => handleSubTab(e, "strategy")}
                  items={[
                    {
                      label: `Strategy`,
                      key: "strategy",
                      children: <></>,
                    },
                    {
                      label: `Supporting information`,
                      key: "docs",
                      children: <></>,
                    },
                    {
                      label: `Action plan`,
                      key: "plan",
                      children: <></>,
                    },
                  ]}
                />
              ),
            },
            {
              label: `Consultation`,
              key: "consultation",
              children: (
                <Tabs
                  centered
                  size={"large"}
                  className={s.tabs}
                  defaultActiveKey="link"
                  onChange={(e) => handleSubTab(e, "consultation")}
                  items={[
                    {
                      label: `Have your say`,
                      key: "link",
                      children: <></>,
                    },
                    {
                      label: `Improving the strategy`,
                      key: "views",
                      children: <></>,
                    },
                  ]}
                />
              ),
            },
            {
              label: `Next steps`,
              key: "steps",
              children: <></>,
            },
          ]}
        />
      </Header>

      <div className={"body"}>
        <div className={"body-container"}>
          <div className="markdown-wrapper">
            {introBackground && (
              <section>
                <h3 className={"section-header-short"}>Background</h3>
                <MarkdownContent pathname="/airQualityStrategy/intro-background" />
              </section>
            )}
            {introWhy && (
              <section>
                <h3 className={"section-header-short"}>Why?</h3>
                <MarkdownContent pathname="/airQualityStrategy/intro-why" />
              </section>
            )}
            {introVision && (
              <section>
                <h3 className={"section-header-short"}>Vision and Aims</h3>
                <MarkdownContent pathname="/airQualityStrategy/intro-vision" />
              </section>
            )}
            {strategyStrategy && (
              <section>
                <h3 className={"section-header-short"}>Strategy</h3>
                <MarkdownContent pathname="/airQualityStrategy/strategy-strategy" />
                <iframe
                  src="https://cdn.cms42.com/essexair/reports/Strategy/essex_air_quality_strategy_web_version.pdf"
                  title="Essex Air Quality Strategy"
                  width="100%"
                  height="500"
                />
              </section>
            )}
            {strategyDocs && (
              <section>
                <h3 className={"section-header-short"}>
                  Supporting information
                </h3>
                <MarkdownContent pathname="/airQualityStrategy/strategy-docs" />
                <iframe
                  src="https://cdn.cms42.com/essexair/reports/Strategy/essex_air_quality_strategy_supporting_information_web_version.pdf"
                  title="Essex Air Quality Strategy Supporting Information"
                  width="100%"
                  height="500"
                />
              </section>
            )}
            {strategyPlan && (
              <section>
                <h3 className={"section-header-short"}>Action plan</h3>
                <MarkdownContent pathname="/airQualityStrategy/strategy-plan" />
                <iframe
                  src="https://cdn.cms42.com/essexair/reports/Strategy/essex_air_quality_strategy_action_plan_web_version.pdf"
                  title="Essex Air Quality Strategy Action Plan"
                  width="100%"
                  height="500"
                />
              </section>
            )}
            {consultationViews && (
              <section>
                <h3 className={"section-header-short"}>
                  Improving the strategy
                </h3>
                <MarkdownContent pathname="/airQualityStrategy/consultation-views" />
              </section>
            )}
            {consultationLink && (
              <section>
                <h3 className={"section-header-short"}>Have your say</h3>
                <MarkdownContent pathname="/airQualityStrategy/consultation-link" />
              </section>
            )}
            {steps && (
              <section>
                <h3 className={"section-header-short"}>Next steps</h3>
                <MarkdownContent pathname="/airQualityStrategy/next-steps" />
              </section>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default AirQualityStrategy;
